import { Route, Routes } from 'react-router-dom';
import ErrorPage from './Pages/404/404';
import AboutUs from './Pages/AboutUs/AboutUs';
import LandingPage from './Pages/LandingPage/LandingPage';
import Membership from './Containers/Membership/Membership';
// import LocalCommitteePages from "./Pages/LocalCommitteePages/LocalCommitteePages";
import ForOrganizations from './Pages/ForOrganizations/ForOrganizations';
import Blog from './Pages/Blog/Blog';
import CookieConsent from 'react-cookie-consent';
import BlogOne from './Pages/BlogOne/BlogOne';
import BlogTwo from './Pages/BlogTwo/BlogTwo';
import BlogThree from './Pages/BlogThree/BlogThree';
import YSFPage from './Pages/YSFPage/YSFPage';
import GMWPage from './Pages/GMWPage/GmwHome';
import GMW2025 from './Pages/GMW2025/Gmw2025Home';
import { ConfigProvider } from 'antd';
import { Helmet } from 'react-helmet-async';
import NvHome from './Pages/NvHome/NvHome';
import NvProjects from './Pages/NvProjects/NvProjects';
import NvSignUp from './Containers/NvSignUp/NvSignUp';
import NvStatesForm from './Containers/NvStatesForm/NvStatesForm';
import './fonts/Expose-Regular.ttf';

function App() {
  return (
    <>
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>AIESEC in Nigeria</title>
        <meta name="title" content="AIESEC in Nigeria" />
        <meta name="description" content="AIESEC in Nigeria" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://aiesec.ng" />
        <meta property="og:title" content="AIESEC in Nigeria" />
        <meta property="og:description" content="AIESEC in Nigeria" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/skycoder/image/upload/v1715860313/logo_ih0mgq.png"
        />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://aiesec.ng" />
        <meta property="twitter:title" content="AIESEC in Nigeria" />
        <meta property="twitter:description" content="AIESEC in Nigeria" />
        <meta
          property="twitter:image"
          content="https://res.cloudinary.com/skycoder/image/upload/v1715860313/logo_ih0mgq.png"
        />
      </Helmet>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Lato, sans-serif',
          },
        }}
      >
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/membership" element={<Membership />} />
          {/* <Route
        path="/local-committee/:localCommittee"
        element={<LocalCommitteePages />}
      /> */}
          <Route path="/for-organizations" element={<ForOrganizations />} />
          <Route path="/ysf" element={<YSFPage />} />
          <Route path="/gmw" element={<GMWPage />} />
          <Route path="/gmw/2025" element={<GMW2025 />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/1" element={<BlogOne />} />
          <Route path="/blog/2" element={<BlogTwo />} />
          <Route path="/blog/3" element={<BlogThree />} />
          <Route path="/nv" element={<NvHome />} />
          <Route path="/nv/projects" element={<NvProjects />} />
          <Route path="/nv/sign-up" element={<NvSignUp />} />
          <Route path="/nv/sign-up/:city" element={<NvStatesForm />} />
        </Routes>
        <CookieConsent
          debug={true}
          buttonText={'I Agree'}
          buttonStyle={{
            background: '#047EF3',
            color: '#ffffff',
            fontSize: '15px',
            width: '150px',
            height: '40px',
          }}
        >
          AIESEC in NIGERIA, requests your consent to store cookies on your
          terminal equipment and to collect information from your terminal
          equipment for the purposes of improving its services and tailoring the
          marketing you see on apps and other websites.
        </CookieConsent>
      </ConfigProvider>
    </>
  );
}

export default App;
